import { useState } from "react";
import React from 'react'
import {Tooltip, Box }from '@mui/material';
import DataTable from "react-data-table-component";
import DataTableExtensions from 'react-data-table-component-extensions'
import 'react-data-table-component-extensions/dist/index.css';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
function Cuentas() {
    const URL = '/post-data-ctasxcobrar'
    const url_ven = '/post-data-vendedores'
    const fileType = "xlsx"
    const nameFileType = "CUBO_SALDOSCxC"

    const [sl_t_suc, setsl_t_suc] = useState('');
    const changeSl_t_suc = (event) => { 
        setsl_t_suc(event.target.value); 
        fc_lstVen(event.target.value);
    }
    
    const [sl_t_año, setsl_t_año] = useState('9999');
    const changeSl_t_año = (event) => { setsl_t_año(event.target.value); fc_lstVen(event.target.value);
    }
    const currentYear = new Date().getFullYear();
    const startYear = 2022;

    const [sl_vendedores, setsl_vendedores] = useState([]);

    const [sl_vende, setsl_vende] = useState('');
    const changeSl_vende = (event) => { setsl_vende(event.target.value); }
   
    const [rad_tip1, setRad_tip1] = useState("TipPaneton");
    const changeRad_tip1 = (event) => { setRad_tip1(event.target.value); }

    const [pending, setPending] = useState(false);
    const [users, setUsers] = useState( [] )


    const columns = [
        { name: 'Sucursal',             selector: row => row.Sucursal,          cellExport: row => row.Sucursal,          sortable: true },
        { name: 'Documento',            selector: row => row.Documento,         cellExport: row => row.Documento,         sortable: true, width: "9rem" },
        { name: 'Cliente',              selector: row => row.Cliente,           cellExport: row => row.Cliente,           sortable: true, width: "20rem" },
        { name: 'Canal',                selector: row => row.Canal,             cellExport: row => row.Canal,             sortable: true },
        { name: 'Total',                selector: row => row.Total,             cellExport: row => row.Total,             sortable: true },
        //{ name: 'Linea', selector: row => row.Linea, sortable: true },
        { name: 'FEmision',             selector: row => row.FEmision,          cellExport: row => row.FEmision,          sortable: true, width: "9rem" },
        { name: 'FVencimiento',         selector: row => row.FVencimiento,      cellExport: row => row.FVencimiento,      sortable: true, width: "9rem" },
        { name: 'Saldo',                selector: row => row.Saldo,             cellExport: row => row.Saldo,             sortable: true },
        { name: 'Dias',                 selector: row => row.Dias,              cellExport: row => row.Dias,              sortable: true },
        { name: 'CondVenta',            selector: row => row.CondVenta,         cellExport: row => row.CondVenta,         sortable: true },
        { name: 'ID_Agente',            selector: row => row.ID_Agente,         cellExport: row => row.ID_Agente,         sortable: true },
        { name: 'FechaGuia',            selector: row => row.FechaGuia,         cellExport: row => row.FechaGuia,         sortable: true },
        { name: 'DiasTranscurridos',    selector: row => row.DiasTranscurridos, cellExport: row => row.DiasTranscurridos, sortable: true },
        { name: 'Portafolio',           selector: row => row.Portafolio,        cellExport: row => row.Portafolio,        sortable: true, width: "9rem" },
        { name: 'Observacion',           selector: row => row.Observacion,        cellExport: row => row.Observacion,        sortable: true, width: "9rem" },
        { name: 'Mesa',           selector: row => row.Mesa,        cellExport: row => row.Mesa,        sortable: true, width: "10rem" },
        { name: 'Ruta',           selector: row => row.Ruta,        cellExport: row => row.Ruta,        sortable: true, width: "15rem" },
    ];
    let body = { sl_t_suc: sl_t_suc, sl_vendedor: sl_vende, sl_t_año:sl_t_año, rad_tip1:rad_tip1};
    const opPe = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body) }
    const fc_lstVen = async (txt_sucu) => {
        let bodyV = { sl_t_suc: txt_sucu, sl_vendedor: sl_vende, sl_t_año:sl_t_año, rad_tip1:rad_tip1};
        const opPeV = { method: "POST", headers: {'Content-Type': 'application/json'}, body: JSON.stringify(bodyV) }

        const response = await fetch(url_ven,opPeV);
        const data = await response.json();
        setsl_vendedores(data)
    }
    const onClickProce = async () => {
        setPending(true)
        document.getElementById('btn_procesar').disabled = "true";
        document.getElementById('btn_procesar_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_spinner').className = 'spinner-border';
        const showData = async () => {
            const response = await fetch(URL,opPe);
            const data = await response.json();
            const tableData = { columns, data, };
            setUsers(tableData)
            document.getElementById('btn_procesar').disabled = "";
            document.getElementById('btn_procesar_icon').setAttribute('class', 'bx bx-cloud-download bx-ms');
            document.getElementById('btn_procesar_spinner').className = 'spinner-border d-none';
            setPending(false)
            
        }
        showData() 
    }
    const onClickExcel = async () => {
        document.getElementById('btn_procesar_excel').disabled = "true";
        document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'd-none');
        document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border';
        const showData = async () => {
          const response = await fetch(URL,opPe);
          const datadtos = await response.json();
          const ws = XLSX.utils.json_to_sheet(datadtos);
          const wb = {Sheets:{data:ws}, SheetNames:["data"]};
          const excelBuffer = XLSX.write(wb, {bookType:"xlsx", type:"buffer"});//array
          const data = new Blob([excelBuffer], {type:fileType});
          FileSaver.saveAs(data, `${nameFileType}.xlsx`);

          document.getElementById('btn_procesar_excel').disabled = "";
          document.getElementById('btn_procesar_excel_icon').setAttribute('class', 'bx bxs-file-export bx-ms');
          document.getElementById('btn_procesar_excel_spinner').className = 'spinner-border d-none';
        }
        showData();      
    }

  return (
    <div>
        <h4 className="fw-bold py-3 mb-4"> CUENTAS POR COBRAR </h4>
        <div className="card mb-4">
            <h5 className="card-header">Consultar</h5>
            <div className="card-body">
                <div className="row mb-2">
                    <div className="col-md-2"><label className="form-label">portafolio</label></div>
                    <div className="col-md-3">
                        <div className="form-check">
                            <input name="default-radio-1" className="form-check-input" type="radio" value="TipPaneton" id="defaultRadio1" onChange={changeRad_tip1} checked={rad_tip1 === "TipPaneton"} />
                            <label className="form-check-label" htmlFor="defaultRadio1">
                                Campaña Panetón 2022
                            </label>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="form-check">
                            <input name="default-radio-1" className="form-check-input" type="radio" value="TipOtros" id="defaultRadio2" onChange={changeRad_tip1} checked={rad_tip1 === "TipOtros"}  />
                            <label className="form-check-label" htmlFor="defaultRadio2">
                                Otros
                            </label>
                        </div>
                    </div>
                    <div className="col-md-1">
                        <div className="form-check">
                            <input name="default-radio-1" className="form-check-input" type="radio" value="TipEmpleados" id="defaultRadio2" onChange={changeRad_tip1} checked={rad_tip1 === "TipEmpleados"}  />
                            <label className="form-check-label" htmlFor="defaultRadio2">
                                Empleados
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2"><label className="form-label">SUCURSAL</label></div>
                    <div className="col-md-3">
                        <Tooltip title="Selecionar Almacen" placement="top">
                            <Box>
                                <select className="form-select form-select-sm"  value={sl_t_suc} onChange={changeSl_t_suc} >
                                    <option value={"9999"} >TODOS</option>
                                    <option value={"TUMBES"} >TUMBES</option>
                                    <option value={"TRUJILLO"} >TRUJILLO</option>
                                    <option value={"CHICLAYO"} >CHICLAYO</option>
                                    <option value={"CHIMBOTE"} >CHIMBOTE</option>
                                    <option value={"PIURA"} >PIURA</option>
                                    <option value={"HUAMACHUCO"} >HUAMACHUCO</option>
                                    <option value={"CAJAMARCA"} >CAJAMARCA</option>
                                </select>
                            </Box>
                        </Tooltip>
                    </div>
                    <div className="col-md-3">
                        <Tooltip title="Vendedores" placement="top">
                        <Box>
                            <select className="form-select form-select-sm" value={sl_vende}  onChange={changeSl_vende}>
                                <option value={"9999"} >TODOS</option>
                                {
                                    sl_vendedores?.map((item,index) => {
                                       return <option value={item?.vu_detalle} key={index} >{item?.vu_detalle}</option>
                                    })
                                }
                                
                            </select>
                        </Box>
                        </Tooltip>
                    </div>
                    
                    <div className="col-md-1"><label className="form-label">AÑO</label></div>
                    <div className="col-md-2">
                                        
                         <select className="form-select form-select-sm" value={sl_t_año} onChange={changeSl_t_año}>
                            <option value={9999}>TODOS</option>
                            {Array.from({ length: currentYear - startYear + 1 }, (_, index) => (
                                <option key={index} value={currentYear - index}>
                                {currentYear - index}
                                </option>
                            ))}
                        </select>
                   
                    </div>
                    

                </div>
                &nbsp;
                <div className="row">
                <div className="col-md-11 text-center ">
                        <Tooltip title="Consultar información" placement="top">
                            <button className="btn btn-primary btn-sm" id="btn_procesar" onClick={onClickProce} >
                                <i className='bx bx-cloud-download bx-ms' id="btn_procesar_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_spinner" role="status" aria-hidden="true"></span>
                            </button>
                        </Tooltip>
                        <Tooltip title="Exportar Excel" placement="top">
                            <button className="btn btn-success btn-sm" id="btn_procesar_excel" onClick={onClickExcel} >
                                <i className='bx bxs-file-export bx-ms' id="btn_procesar_excel_icon"></i>
                                <span className="spinner-border d-none" id="btn_procesar_excel_spinner" role="status" aria-hidden="true"></span>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
        <div className="card ">
            <div className="card-body">
                <div className="card-datatable table-responsive">
                    <DataTableExtensions {...users} >
                    <DataTable
                        Header
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        progressPending={pending}
                    />
                    </DataTableExtensions>
                </div>
            </div>
        </div>
  </div>
  )
}

export default Cuentas

/**
 * 

{
                                    sl_vendedores.map((item, index) => {
                                     return (<option value={item['vu_detalle']} >{item['vu_detalle']}</option>)
                                    })
                                     
                                }

 * 
 */